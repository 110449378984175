<template>
  <div class="payment-page">
    <Navbar showUser="false"></Navbar>
    <div class="main">
      <h2 class="r-title-1">Доплата за добавление блюда</h2>
      <iframe id="paymentFrame" :src="GET_PAYMENT_LINK?.link" allowpaymentrequest="true" allow="payment"></iframe>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/navbar";
import Footer from "@/components/footer-setting.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Navbar,
    Footer
  },
  computed: {
    ...mapGetters(["GET_PAYMENT_LINK"])
  }
};
</script>
<style lang="scss">
.payment-page {
  h2.r-title-1 {
    padding: 0 20px;
    margin-top: 82px;
    margin-bottom: 26px;
  }
  .main {
    padding: 20px;
  }
  footer {
    position: initial;
  }
  #paymentFrame {
    width: 100%;
    height: 1200px;
    border: 1px solid #f5f8fa;
    padding: 3px;
  }
}
</style>
